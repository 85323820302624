<div class="ng-header">
    <div class="ng-header-left left">
        <div class="toggle-menu-button left">
            <a class="icon" (click)="toggleMenu()"><i class="fa fa-bars"></i></a>
        </div>
        <!--<div class="logo right">-->
        <div class="logo logo-block">
            <img src="assets/logo.png" class="logo-new">

            <span>Portal del Cliente</span>
        </div>
    </div>

    <span class="header-client-name" style="margin-top: 14px;display: inline-block;">&nbsp;&nbsp;Bienvenid@&nbsp;{{clientName}}</span>
    <div class="ng-header-right right">



        <div class="ng-header-right-item right">

            <a class="icon" (click)="cmd_salir()"><i class="fa fa-sign-out-alt">&nbsp;Salir</i></a>
        </div>
        <div class="ng-header-right-item right" (click)="themeChooser.toggle($event)">
            <a class="icon"><i class="fa fa-cog"></i></a>
        </div>
        <!-- *ngIf="idClient_collaborator=='0'" -->
        <div class="ng-header-right-item right header-client-right" style="margin-top: 14px;display: inline-block;" >
            <select  [(ngModel)]="mySelect" (change)="selectChange()">
                <option>Seleccionar </option>
                <option [value]="company.idCliente" *ngFor="let company of subcompanies">
                    {{company.Cliente}} 
                </option>
            </select>
            <!-- <span><button (click)="updateUrl()">Cambiar de cuenta</button></span> -->
            <span><button (click)="updateUrl()">Cambiar de cuenta</button></span>
        </div>
        <!-- <div class="ng-header-right-item right" (click)="userdetails.toggle($event)">
            <a class="icon"><span [innerHtml]="user.emailId" class="user-name"></span><i class="fa fa-user"></i></a>
        </div> -->
    </div>
</div>

<!-- theme chooser overlay panel -->
<p-overlayPanel #themeChooser>
    Cambiar estilo:
    <div class="ui-g">
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-teal')"
                style="background-color: #427976;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-rose')"
                style="background-color: #79425a;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-light')"
                style="background-color: #ffffff"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-dark')"
                style="background-color: #3b3b48"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-Turquoise')"
                style="background-color:#04838f;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-green')"
                style="background-color: #1e8455;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-blue')"
                style="background-color: #2d5394;"></button>
        </div>
    </div>
</p-overlayPanel>
<div class="ng-content" [ngClass]="{'hidden-menu-content': !isMenuVisible}">
    <p-sidebar [visible]="visible" [showCloseIcon]="false" styleClass="np-menubar align-right" [modal]="false" >


        
        <!-- [baseZIndex]="10000" -->
        <ul class="np-menu">
            <ng-template #recursiveList let-list>
                <!-- <li *ngFor="let menu of list" [ngClass]="{'hidemenu': menu.label == 'Colaboradores' && idClient_collaborator!='0'}"> -->

                    <li *ngFor="let menu of list"  [ngClass]="{'hidemenu': menu.label == 'Colaboradores' && idClient_collaborator!='0' , 

                    'hidemenu1': menu.label == 'Recientes' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_documents_recent=='1', 
                    
                    'hidemenu2': menu.label == 'Enviar documentos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_documents_senddocument=='1',


                    'hidemenu3': menu.label == 'Mis documentos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_documents_mydocument=='1',


                    'hidemenu4': menu.label == 'Impuestos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_documents_taxes=='1', 


                    'hidemenu5': menu.label == 'Consulta' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_consultation=='1', 


                    'hidemenu6': menu.label == 'Asuntos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_issues=='1', 


                    'hidemenu7': menu.label == 'Información' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_information=='1', 


                    'hidemenu8': menu.label == 'Incidencias' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_incidences=='1', 


                    'hidemenu9': menu.label == 'Nóminas' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_names=='1', 


                    'hidemenu10': menu.label == 'Resumen nóminas' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_payrollsummery=='1', 


                    'hidemenu11': menu.label == 'Doc. Empleados' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_docemployees=='1', 


                    'hidemenu12': menu.label == 'Pagos netos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_paidgrandchildren=='1', 


                    'hidemenu13': menu.label == 'Portal empleados' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_employeeportal=='1', 


                    'hidemenu14': menu.label == 'Solicitudes' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_request=='1', 


                    'hidemenu15': menu.label == 'Solicitud Entrada/Salida' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_entryexit_request=='1', 

                    'hidemenu16': menu.label == 'Horario' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_time=='1', 


                    'hidemenu17': menu.label == 'Consulta permisos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_checkpermissions=='1', 


                    'hidemenu18': menu.label == 'Consulta global' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_globalquery=='1', 


                    'hidemenu19': menu.label == 'Portal de denuncias' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_labor_complaintsportal=='1',


                    'hidemenu20': menu.label == 'Mis datos' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_mydata=='1',  

                    'hidemenu21': menu.label == 'Colaboradores' && (idClient_collaborator!='0'  || idClient_collaborator!='') && access_collaborator=='1'
                }">

 
                    
                    
                    <a (click)="onMenuClick(menu)" [ngClass]="{'active': selectedItem == menu.label}" >
                        <i class="fa fa-fw" [ngClass]="menu.icon"></i>
                        {{menu.label}}
                        <i class="fa right" *ngIf="menu.items != null"
                            [ngClass]="{'fa-angle-down': !menu.expanded,'fa-angle-up': menu.expanded  }"></i>
                    </a>
                    <ul *ngIf="menu.items && menu.items.length > 0 && menu.expanded" class="np-sub-menu">
                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menu.items[0][0].items }">
                        </ng-container>
                    </ul>
                   
                </li>
            </ng-template>
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: items1X }"></ng-container>
        </ul>
    </p-sidebar>
  

    <router-outlet></router-outlet>

    <footer>
        
        <div class=" right p-text"  >
            <!-- Versión 28.06.2024 -->
            {{this.angularProjectVersion}}
       </div>

</footer>

</div>