import { Component, OnInit, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { Respuesta } from 'src/app/respuesta';
declare let jsPDF;
// window.jsPDF = window.jspdf.jsPDF;
// import 'jspdf-autotable'
// const doc = new jsPDF();
@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit {

  ejercicio: number;
  mes: string = "01";
  empleados: any = [];
  public selectedEmpleados: any = [];
  ClentName: any;
  clientId: any;
  month: string;
  selectedEmployessForPdfDownload = [];
  constructor(private httpClient: HttpClient, public gds: GlobaldataService,
    private router: Router, private routera: ActivatedRoute, private messageService: MessageService) {
    this.gds.clientDetailsObservable.subscribe((data: any) => {
      this.ClentName = data.cliente, this.clientId = data.idCliente; console.log("client datatatat", data)

    })
  }

  showHorarioDiario(idEmpleado) {
    this.router.navigate(['main/hdiario', idEmpleado, this.ejercicio, this.mes]);
  }
 
  ngOnInit(): void {
    this.ejercicio = this.gds.ejercicio_actual;
    this.mes = this.gds.mes_actual;
    console.log("mes...", this.mes)
    console.log("mes...", this.gds.meses)

    this.loadEmpleados();
  }

  public click_tabla(e) {
    if ('data-id' in e.target.attributes) this.showHorarioDiario(e.target.attributes["data-id"].value);
  }

  public loadEmpleados() {
    let url2 = 'empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
      + "&fields=E.idEmpleado,T.empleado,time_format(sec_to_time(sum(timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo"
      + '&where=E.baja in(0,1) and (H.tipo="" or H.tipo="visita") and  E.idCliente=' + this.gds.id_cliente + " and year(H.inicio)=" + this.ejercicio + " and month(H.inicio)=" + this.mes
      + '&groupby=T.empleado'
      + '&order=T.empleado';

    console.log(this.gds.urlBaseDL + url2);
    console.log("called in load employeee")
    return this.httpClient.get(this.gds.urlBaseDL + url2).toPromise().then((r) => {
      this.empleados = r['resp'];
      console.log(this.empleados);
    });
  }

  informeHorario() {
    //if(!this.selectedEmpleados.length) return;
    let ids = this.selectedEmpleados.map((e) => { return e.idEmpleado });
    this.messageService.add({ severity: 'info', summary: 'Informe horario', detail: 'En breve recibirá el informe solicitado por email!' });
    return this.httpClient.post(this.gds.urlBaseBL + "reportHorario", { ids: ids, mes: this.mes, ej: this.ejercicio, idCliente: this.gds.id_cliente }).toPromise().then((r) => {
      console.log(r);
    });
  }
  loadPermisos() {
    let date = this.ejercicio

    this.ejercicio
    let data = {
      idCliente: this.clientId,
      fechainicio: this.ejercicio,
      month: this.mes
    }
    const month = [
      {
        "label": "Enero",
        "value": "01"
      },
      {
        "label": "Febrero",
        "value": "02"
      },
      {
        "label": "Marzo",
        "value": "03"
      },
      {
        "label": "Abril",
        "value": "04"
      },
      {
        "label": "Mayo",
        "value": "05"
      },
      {
        "label": "Junio",
        "value": "06"
      },
      {
        "label": "Julio",
        "value": "07"
      },
      {
        "label": "Agosto",
        "value": "08"
      },
      {
        "label": "Septiembre",
        "value": "09"
      },
      {
        "label": "Octubre",
        "value": "10"
      },
      {
        "label": "Noviembre",
        "value": "11"
      },
      {
        "label": "Diciembre",
        "value": "12"
      }
    ].filter((data) => data.value == this.mes).map(data => data.label).toString();
    this.month = month
    console.log("month", month)
    console.log("in report call")
    this.gds.downloadLeaveReport(data).subscribe((res: any) => {
      console.log(res)
      console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
      if (this.selectedEmployessForPdfDownload.length > 0 && res.length>this.selectedEmployessForPdfDownload.length) {
        res = res.map(data => {
          if (this.selectedEmployessForPdfDownload.includes(data.employeeName)) {
            console.log("in if")
            return data
          } else {
            console.log("in else")
            return this.selectedEmployessForPdfDownload.map((data,i) => {
              let c=i+1
              console.log("c",c)
                return {
                  "employeeName": data,
                  // "totalLeave": "totalLeave",
                  "Asistencia al médico": 0,
                  "Vacaciones": 0,
                  "Enfermedad": 0,
                  "Libre disposición": 0,
                  "Matrimonio": 0,
                  "Nacimiento de hijon": 0,
                  "Fallecimiento familiar": 0,
                  "Realización de examen, curso": 0,
                  "Excedencia por conciliación": 0,
                  "Excedencia voluntaria": 0,
                  "Baja de la empresa": 0,
                  "Cambio de jornada": 0
                }
            })
          }
        }).flat();
      }else if(this.selectedEmployessForPdfDownload.length >res.length){
        this.selectedEmployessForPdfDownload.forEach(data=>{
          let obj={
            "employeeName": data,
            // "totalLeave": "totalLeave",
            "Asistencia al médico": 0,
            "Vacaciones": 0,
            "Enfermedad": 0,
            "Libre disposición": 0,
            "Matrimonio": 0,
            "Nacimiento de hijon": 0,
            "Fallecimiento familiar": 0,
            "Realización de examen, curso": 0,
            "Excedencia por conciliación": 0,
            "Excedencia voluntaria": 0,
            "Baja de la empresa": 0,
            "Cambio de jornada": 0
          }
             res.push(obj)
        })
      }
      console.log("cccc", res)
      res=res.filter((a, i) => res.findIndex((s) => a.employeeName === s.employeeName) === i)
      console.log("cccc", res)

      let doc = new jsPDF('l', 'pt', 'a4');

      let columns = [
        { title: "Empleado", dataKey: "employeeName" },
        // { title: "totalLeave", dataKey: "totalLeave" },
        { title: "Asistencia al médico", dataKey: "Asistencia al médico" },
        { title: "Vacaciones", dataKey: "Vacaciones" },
        { title: "Enfermedad", dataKey: "Enfermedad" },
        { title: "Libre disposición", dataKey: "Libre disposición" },
        { title: "Matrimonio", dataKey: "Matrimonio" },
        { title: "Nacimiento de hijon", dataKey: "Nacimiento de hijo" },
        { title: "Fallecimiento familiar", dataKey: "Fallecimiento familiar" },
        { title: "Realización de examen, curso", dataKey: "Realización de examen, curso" },
        { title: "Excedencia por conciliación", dataKey: "Excedencia por conciliación" },
        { title: "Excedencia voluntaria", dataKey: "Excedencia voluntaria" },
        { title: "Baja de la empresa", dataKey: "Baja de la empresa" },
        { title: "Cambio de jornada", dataKey: "Cambio de jornada" },


      ];
      let clintName = this.ClentName;
      let width = doc.internal.pageSize.getWidth()
      doc.autoTable(columns, res, {
        margin: { top: 100 },
        addPageContent: function (data) {
          doc.setFontSize(9);

          doc.text(`INFORME DE ASISTENCIA SOLICITADO Y GENERADO PARA ${date} ${month.toUpperCase()}`, width / 2, 20, { align: 'center' });
          doc.text(`EMPRESA:       ${clintName}`, 40, 60);
          doc.text(`INFORME GENERADO EL: ${new Date().toLocaleDateString()}`, 40, 80);
        }
      });

      doc.save(`${date}-${month.toUpperCase()}.pdf`);
    })
  }
  empleadoSelection(emp,event) {
    console.log("event...",event.checked)

    if (this.selectedEmployessForPdfDownload.includes(emp.empleado)) {
      this.selectedEmployessForPdfDownload = this.selectedEmployessForPdfDownload.filter(data => data != emp.empleado)
    } else {
      this.selectedEmployessForPdfDownload.push(emp.empleado)
    }
    console.log("this.empleado", emp)
    console.log("this.selectedEmployessForPdfDownload", this.selectedEmployessForPdfDownload)
  }
  empleadoSelectionSelectAll(empleados,event) {
    console.log("event...all",event.checked)

    console.log("empleados", empleados)
    if(event.checked==true){

      this.selectedEmployessForPdfDownload = empleados.map(data => data.empleado)
    }else{
      this.selectedEmployessForPdfDownload=[];
    }
  }

}
