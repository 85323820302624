import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api'; 
import {MenubarModule} from 'primeng/menubar';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
//import * as angular from "angular";
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {TabMenuModule} from 'primeng/tabmenu';
import { Utilidades } from '../../libs/utilidades';

@Component({
  selector: 'app-misdatos',
  templateUrl: './misdatos.component.html',
  styleUrls: ['./misdatos.component.scss']
})
export class MisdatosComponent implements OnInit {

  items2: MenuItem[];
  menuId2: number=0;
  datosCliente:any;
  datosClienteLocal:any={tipo:{}};
  pwd:String="";
  pwdNueva:String="";
  missdatosTabCheck:any;
	
  constructor(private httpClient: HttpClient,private gds:GlobaldataService,private messageService: MessageService) { }

  ngOnInit() {

	//alert("ddd");
	//this.missdatosTabCheck = sessionStorage.getItem("missdatosTabCheck");
	this.missdatosTabCheck = 1;
	//alert(this.missdatosTabCheck);

	if(this.missdatosTabCheck==0)
	{
		this.items2 = [
			{id:'0',label:'Contraseña',icon: 'pi pi-key',command: (event: Event) => { this.menuId2=0; }}
		];
	}
	else if(this.missdatosTabCheck==1)
	{
		this.items2 = [
			
			{id:'1',label: 'Datos de contacto', icon: 'pi pi-home',command: () => { this.menuId2=1; }}
		];
	}
	else{

		this.items2 = [
			{id:'0',label:'Contraseña',icon: 'pi pi-key',command: (event: Event) => { this.menuId2=0; }},
			{id:'1',label: 'Datos de contacto', icon: 'pi pi-home',command: () => { this.menuId2=1; }}
		];
		

	}

	
	this.httpClient.get(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente).subscribe((res)=>{
        this.datosCliente=res['resp'];
		this.datosClienteLocal=Object.assign({},this.datosCliente);
		console.log(this.datosCliente);
		console.log(this.datosClienteLocal);
    });
  }
  
  cmd_updateDatos()
  {
	var cambios=Utilidades.computeDiffs(this.datosClienteLocal,this.datosCliente);
	console.log(cambios);
	console.log(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente);
	
	this.httpClient.put(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente,cambios).subscribe((res)=>{
		console.log(res);
		this.datosCliente=Object.assign({},this.datosClienteLocal);
		this.messageService.add({severity:'success', summary:'', detail:'Datos actualizados!'});
	});
  }
  
  cmd_actualizar()
  {
	if(this.pwd===this.gds.pwd_cliente)
	{
		if (this.pwdNueva.length<5)
		{
			this.messageService.add({severity:'warn', summary:'ERROR', detail:'La contraseña debe tener al menos cuatro letras!'});
		}
		else
		{
			this.httpClient.put(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente,{password:this.pwdNueva}).subscribe((res)=>{
				console.log(res);
				this.messageService.add({severity:'success', summary:'', detail:'Su contraseña se ha actualizado!'});
				this.gds.pwd_cliente=this.pwd;
			});
		}
	}
	else
	{
		this.messageService.add({severity:'warn', summary:'ERROR', detail:'Contraseña actual incorrecta!'});
	}
		
  }
  	
}
