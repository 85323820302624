<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion"> Mis datos personales</p>

<!--<p-menubar [autoDisplay]="false" [model]="items2"></p-menubar>-->

<!-- <p-tabMenu  [model]="items2" [activeItem]="items2[0]"></p-tabMenu> -->

<p-tabMenu  [model]="items2" [activeItem]="items2[missdatosTabCheck]"></p-tabMenu>

<br/>

<!--<div style="display:flex;justify-content: center;">-->

<div *ngIf="missdatosTabCheck==0" >
<!-- <div *ngIf="menuId2==0" class="p-grid p-justify-start mlr-20"> -->
	<div  class="p-grid p-justify-start mlr-20">
<div class="p-col-12 p-md-12 p-lg-12">

<fieldset>
	<div class="p-grid">
		<div class="p-col-12">
<legend>Cambio de contraseña:</legend>
	<small>Contraseña actual:</small>
	<input id="txtPwdActual" autocomplete="off" type="text" size="30"  maxlength="45" pInputText class="voraz" [(ngModel)]="pwd"> 

	<small>Nueva contraseña:</small>
	<input id="txtPwdNew" autocomplete="off" type="text" size="30"  maxlength="45" pInputText class="voraz" [(ngModel)]="pwdNueva"> 
</div>
</div>
</fieldset>

<br/><br/>
<div class="centrado">
	<button pButton (click)="cmd_actualizar()" type="button" label="Cambiar contraseña" class="ui-button-raised"></button>
</div>

</div>
</div>
</div>






<div *ngIf="missdatosTabCheck==1" >
<!-- <div *ngIf="menuId2==1" class="p-grid p-justify-start mlr-20"> -->
	<div  class="p-grid p-justify-start mlr-20">
<div class="p-col-12 p-md-12 p-lg-12">


	<fieldset>
		<div class="p-grid">
			<div class="p-col-12">
<legend>Datos de contacto:</legend>

	<small>Email:</small>
	<input autocomplete="off" type="text" size="30" pInputText class="voraz" [(ngModel)]="datosClienteLocal.email"> 

	<small>Teléfono Móvil:</small>
	<input autocomplete="off" type="text" size="30" pInputText class="voraz" [(ngModel)]="datosClienteLocal.telefonoMovil"> 

	<small>Teléfono:</small>
	<input id="float-input" autocomplete="off" type="text" size="30" pInputText class="voraz" [(ngModel)]="datosClienteLocal.telefono"> 

	<small>Fax:</small>
	<input id="float-input" autocomplete="off" type="text" size="30" pInputText class="voraz" [(ngModel)]="datosClienteLocal.Fax"> 

	<small>WhatsApp:</small>
	<input id="float-input" autocomplete="off" type="text" size="30" pInputText class="voraz" [(ngModel)]="datosClienteLocal.whatsAppNum"> 
	
</div>
</div>
</fieldset>


<br/><br/>
<div class="centrado">
	<button pButton (click)="cmd_updateDatos()" type="button" label="Actualizar" class="ui-button-raised"></button>
</div>

</div>
</div>
</div>

<p-toast position='center'></p-toast>