
<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Mis asuntos</p>
    <div class="grid filtro r-grid bg-sec">
        <ul class="customlista"  (click)="verTramite($event);">
            
        <li *ngFor="let tarea of tareas" [attr.data-id]='tarea.idTarea'>
            <div class="fecha">{{tarea.fecha.substr(0,10)}} </div> 
            <i class="pi pi-list icono "></i><span class=" affair-desc">{{tarea.Tarea}} </span></li>
        </ul>
    </div>
<app-asunto #asunto id="asunto">
</app-asunto>   
