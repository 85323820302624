import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpEventType } from '@angular/common/http';
import { GlobaldataService } from '../../servicios/globaldata.service';
import { Router,ActivatedRoute,Params  } from "@angular/router";

@Component({
  selector: 'app-hdiario',
  templateUrl: './hdiario.component.html',
  styleUrls: ['./hdiario.component.scss']
})
export class HdiarioComponent implements OnInit {

  idEmpleado:Number=0;
  saldos:any=[];
  ejercicio: number;
  mes:string="01";
  empleado:string=""; 
  
  constructor(private httpClient: HttpClient,public gds:GlobaldataService,
    private router:Router,private routera: ActivatedRoute)
    {

    }

  ngOnInit(): void {
    this.routera.params.subscribe((params: Params) => 
    {
      this.idEmpleado = params['idEmpleado'];
      this.ejercicio = params['ej']; 
      this.mes = params['mes'];
      this.loadSaldosDiarios(); 
    });
  }

  goback_hora() {

    console.log("previous url is: " +window.history);
    history.back();
    //this.router.navigate(['main/horarioemp']);
    //this.router.navigate(['main/consultapermisos']);


  }


  horaToMins(hora)
  {
    return parseInt(hora.substr(0,2))*60+parseInt(hora.substr(3,2));
  }

  minsToHora(m)
  {
    return (String(Math.floor(m/60))).padStart(2,"0")+":"+(String(m%60)).padStart(2,"0");
  }

  public loadSaldosDiarios()
	{
    let url2='empleado?&joins=trabajador(E.idTrabajador=>T.idTrabajador),horario(E.idEmpleado=>H.idEmpleado)'
    +"&fields=E.idEmpleado,T.empleado,H.tipo,date_format(H.inicio,'%25d/%25m/%25Y') as H_fecha,time_format(time((H.inicio)),'%25H:%25i') as H_entrada,time_format(time((H.fin)),'%25H:%25i') as H_salida,time_format(sec_to_time((timestampdiff(second, inicio, fin))), '%25H:%25i') as H_saldo,H.detalle,H.localizacion"
    +'&where=E.baja in(0,1) and E.idEmpleado='+this.idEmpleado+" and year(H.inicio)="+this.ejercicio+" and month(H.inicio)="+this.mes
    +'&order=inicio'
    +'&tree=empleado->fichajes,horario.fecha->inout=>[horario.fecha,horario.entrada,horario.salida,horario.saldo,horario.tipo,horario.detalle,horario.localizacion]';

    return this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      console.log("lokooooooo",r['resp'][0]);
      this.saldos=r['resp'][0].fichajes.map((f,index)=>{
        f['id']=index;
        let saldo=0;
        for (var fic of f.inout)
        {

            if(fic[3]==null){fic[3]="00:00";}
            if(fic[5]!="") fic[4]+=" ("+fic[5]+")";
            if(fic[4]!="" && fic[4]!="visita") fic[3]="00:00";
            if (fic[2]==null) continue;
            let s=fic[3];
            //let ss=s.split(":"); 
            //console.log(s,ss[0],ss[1]);
            saldo+=this.horaToMins(s); 
        }
        f['saldo']=this.minsToHora(saldo);
        return f;
      });
      this.empleado=r['resp'][0].label;
      //console.log(this.saldos);
      console.log("tokooooooo",this.saldos);
    });
  }

}
