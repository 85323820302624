import { Component, OnInit, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';     //accordion and accordion tab
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { StepsModule } from 'primeng/steps';

import { MenubarModule } from 'primeng/menubar';

import { CardModule } from 'primeng/card';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { FileUploadModule, FileUpload } from 'primeng/fileupload';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobaldataService } from '../servicios/globaldata.service';
import { MisasuntosComponent } from './misasuntos/misasuntos.component';
import { MegaMenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { ThemeService } from 'src/app/core/services/theme.service';
import { SessionService } from 'src/app/core/services/session.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { MenuDataService } from 'src/app/core/services/menu-data.service';
import { RouteStateService } from 'src/app/core/services/route-state.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    @ViewChild('menuCtx') menuCtx: ContextMenu;
    subcompanies: any = [];
    selected: any;
    selectedItem: string;
    visible: boolean;
    isMenuVisible: boolean;
    clientName: any;
    companydata: any;
    mySelect: any = "choose";
    mySelectCliente: any = [];
    clientIdAllArr: any;
    angularProjectVersion:string="";
    missdatosTabCheck: any;
    
    constructor(private router: Router, private routera: ActivatedRoute, private gds: GlobaldataService, private routeStateService: RouteStateService, private sessionService: SessionService, private themeService: ThemeService, private menuDataService: MenuDataService, private applicationStateService: ApplicationStateService) {
        var selectedTheme = this.sessionService.getItem("selected-theme");
        if (selectedTheme) {
            this.selectTheme(selectedTheme);
        }

    }



    title = 'Portal del cliente';


    items1: MenuItem[];
    itemsMenu: MegaMenuItem[];
    itemsctx: MenuItem[];
    items1X: MegaMenuItem[];
    activeItem1: MenuItem;
    activeIndex1: number = 0;

    items2: MenuItem[];
    menuId2: number = 0;

    items10: MenuItem[];
    menuId: number = 0;

    activeItem: MenuItem;

    itemss: MenuItem[];
    activeIndex2: number = 0;

    itemsArbol: TreeNode[];

    myfile: string[];

    consulta: string = "";
    idClient_collaborator:any ="";
    clientIdAll:any ="";
    hidemenu: string = "";
    collaborator_cif:any ="";
    collaborator_apikey:any ="";


    access_documents_recent:any ="";
    access_documents_senddocument:any ="";
    access_documents_mydocument:any ="";
    access_documents_taxes:any ="";
    access_consultation:any ="";
    access_issues:any ="";
    access_information:any ="";
    access_labor_incidences:any ="";
    access_labor_names:any ="";
    access_labor_payrollsummery:any ="";
    access_labor_docemployees:any ="";
    access_labor_paidgrandchildren:any ="";
    access_labor_employeeportal:any ="";
    access_labor_request:any ="";
    access_labor_entryexit_request:any ="";
    access_labor_time:any ="";
    access_labor_checkpermissions:any ="";
    access_labor_globalquery:any ="";
    access_labor_complaintsportal:any ="";
    access_mydata:any ="";
    access_collaborator:any ="";
    access_mylatest_documents:any ="";
    access_mylatest_affairs:any ="";
    

    ngOnInit() {

        this.idClient_collaborator = this.gds.idClient_collaborator;
        this.clientIdAll = this.gds.clientIdAll;
        this.collaborator_cif = this.gds.collaborator_cif;
        this.collaborator_apikey = this.gds.collaborator_apikey;
        //alert(this.idClient_collaborator+"---"+this.clientIdAll);


        this.access_documents_recent = this.gds.access_documents_recent;
        this.access_documents_senddocument = this.gds.access_documents_senddocument;
        this.access_documents_mydocument = this.gds.access_documents_mydocument;
        this.access_documents_taxes = this.gds.access_documents_taxes;
        this.access_consultation = this.gds.access_consultation;
        this.access_issues = this.gds.access_issues;
        this.access_information = this.gds.access_information;
        this.access_labor_incidences = this.gds.access_labor_incidences;
        this.access_labor_names = this.gds.access_labor_names;
        this.access_labor_payrollsummery = this.gds.access_labor_payrollsummery;
        this.access_labor_docemployees = this.gds.access_labor_docemployees;
        this.access_labor_paidgrandchildren = this.gds.access_labor_paidgrandchildren;
        this.access_labor_employeeportal = this.gds.access_labor_employeeportal;
        this.access_labor_request = this.gds.access_labor_request;
        this.access_labor_entryexit_request = this.gds.access_labor_entryexit_request;
        this.access_labor_time = this.gds.access_labor_time;
        this.access_labor_checkpermissions = this.gds.access_labor_checkpermissions;
        this.access_labor_globalquery = this.gds.access_labor_globalquery;
        this.access_labor_complaintsportal = this.gds.access_labor_complaintsportal;
        this.access_mydata = this.gds.access_mydata;
        this.access_collaborator = this.gds.access_collaborator;
        this.access_mylatest_documents = this.gds.access_mylatest_documents;
        this.access_mylatest_affairs = this.gds.access_mylatest_affairs;
        this.idClient_collaborator = this.gds.idClient_collaborator;

        console.log("access_documents_recent",this.access_documents_recent);
        console.log("access_documents_senddocument",this.access_documents_senddocument);
        console.log("access_documents_mydocument",this.access_documents_mydocument);




        let angularProjectVersion =this.gds.angularProjectVersion;
        console.log("ddddddddddddddd",angularProjectVersion);
        this.angularProjectVersion =""+angularProjectVersion+"";
       // alert(angularProjectVersion);








        this.hidemenu = 'hidemenu';
        //this.router.navigate(['/heroes']);
        this.gds.clientNameObservable.subscribe((name: any) => {
            //console.log("name....",name);
            this.clientName = name;
        });
        this.gds.subCompanyObservable.subscribe((data: any) => {
            console.log("name..fffffffff..",data);
            this.subcompanies = data;
            if(this.clientIdAll!='0')
            {
                //this.clientIdAllArr=this.clientIdAll.split(",");
                //console.log("aaaaaaaaaaaaaa",this.clientIdAllArr); 
                let arrsubcompanies=[];
                this.clientIdAllArr = this.clientIdAll.split(',').map(Number);
                console.log("bbbbbbbbbbb",this.clientIdAllArr); 
                var k=0;
                for(var p=0;p<this.subcompanies.length;p++)
                {
                    console.log("xxxaaaaaaaaaa",this.subcompanies[p].idCliente); 
                    if(this.clientIdAllArr.indexOf(this.subcompanies[p].idCliente)!=-1)
                    {
                        arrsubcompanies[k]=this.subcompanies[p];
                        k++;
                    }
                    
                }
                console.log("name..sssssssssssss..", arrsubcompanies);
                this.subcompanies=arrsubcompanies;
            }
           
           
            
        });
        this.items1 = [
            
            {
                id: '1', label: 'Documentos', icon: 'pi pi-folder-open', title: 'Documentos',
                items: [
                    { id: '0', label: 'Recientes', icon: 'pi pi-clock', title: 'Recientes', routerLink: ['recientes'], routerLinkActiveOptions: { exact: true } },
                    { label: 'Enviar documentos', routerLink: ['docenviar'], icon: 'pi pi-upload', routerLinkActiveOptions: { exact: true } },
                    { label: 'Mis documentos', routerLink: ['docmisdocs'], icon: 'pi pi-folder-open', routerLinkActiveOptions: { exact: true } },
                    { label: 'Impuestos', routerLink: ['docimpuestos'], icon: 'fa fa-fw fa-calendar', routerLinkActiveOptions: { exact: true } }], routerLinkActiveOptions: { exact: true }
            },
            { id: '2', label: 'Consulta', icon: 'pi pi-comments', title: 'Consultas', command: (event: Event) => { this.router.navigate(['consultas'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: true } },
            { id: '3', label: 'Asuntos', icon: 'pi pi-list', title: 'Mis Asuntos', command: (event: Event) => { this.router.navigate(['misasuntos'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: true } },
            { id: '4', label: 'Información', icon: 'fa fa-fw fa-book', title: 'Información', command: (event: Event) => { this.router.navigate(['informacion'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: true } },
            {
                id: '5', label: 'Laboral1', icon: 'pi pi-folder-open', title: 'Laboral11',
                items: [
                    { label: 'Incidencias', routerLink: ['incidencias'], icon: 'fas fa-exclamation-triangle', routerLinkActiveOptions: { exact: true } },
                    { label: 'Nóminas', routerLink: ['nominas'], icon: 'fas fa-euro-sign', routerLinkActiveOptions: { exact: true } },
                    { label: 'Resumen nóminas', routerLink: ['resnom'], icon: 'fa fa-table', routerLinkActiveOptions: { exact: true } },
                    { label: 'Doc. Empleados', routerLink: ['docsemp'], icon: 'pi pi-folder-open', routerLinkActiveOptions: { exact: true } },
                    { label: 'Pagos netos', routerLink: ['costes'], icon: 'pi pi-chart-line', routerLinkActiveOptions: { exact: true } },
                    { label: 'Portal empleados', routerLink: ['portalemp'], icon: 'pi pi-users', routerLinkActiveOptions: { exact: true } },
                    { label: 'Solicitudes', routerLink: ['solicitudesemp'], icon: 'pi pi-users', routerLinkActiveOptions: { exact: true } },
                    { label: 'Horario', routerLink: ['horarioemp'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                    { label: 'Consulta permisos', routerLink: ['consultapermisos'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } }, 
                    // { label: 'Consulta GLOBAL', routerLink: ['consultaglobal'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                    { label: 'Consulta global', routerLink: ['consultaglobalmonth'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                    { label: 'Portal de denuncias', routerLink: ['complaints'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } } ,
                    { label: 'Responsables', routerLink: ['clientcollaborators'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: false } },
                    { label: 'Departamentos', routerLink: ['clientdepartments'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: false } },
                    { label: 'Proyectos', routerLink: ['clientprojects'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: false } }
                ], routerLinkActiveOptions: { exact: true } 
            },
            { id: '6', label: 'Mis datos', icon: 'pi pi-user', title: 'Mis datos', command: (event: Event) => { this.router.navigate(['misdatos'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: true } },

            { id: '7', label: 'Responsables', icon: 'pi pi-user', title: 'Responsables', command: (event: Event) => { this.router.navigate(['clientcollaborators'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: false } },

            { id: '8', label: 'Departamentos', icon: 'pi pi-user', title: 'Departments', command: (event: Event) => { this.router.navigate(['clientdepartments'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: false } },

            { id: '9', label: 'Proyectos', icon: 'pi pi-user', title: 'Proyectos', command: (event: Event) => { this.router.navigate(['clientprojects'], { relativeTo: this.routera }); }, routerLinkActiveOptions: { exact: false } },


            { id: '10', label: 'Salir', icon: 'pi pi-sign-out', title: 'Salir', command: (event: Event) => { this.cmd_salir(); }, },
            { id: '11', label: 'Dashboard', icon: 'pi pi-sign-out', title: 'Dashboard', command: (event: Event) => { this.cmd_salir(); }, }

        ];

        this.items1X = [
            { label: 'Escritorio', icon: 'pi pi-th-large', routerLink: ['recientes'], routerLinkActiveOptions: { exact: true } },
            {
                label: 'Documentos', icon: 'pi pi-folder-open',
                items: [
                    [
                        {
                            items:
                                [
                                    // { id: '0', label: 'Recientes', icon: 'pi pi-clock', routerLink: ['recientes'], routerLinkActiveOptions: { exact: true } },
                                    { label: 'Enviar documentos', routerLink: ['docenviar'], icon: 'pi pi-upload', routerLinkActiveOptions: { exact: true } },
                                    { label: 'Mis documentos', routerLink: ['docmisdocs'], icon: 'pi pi-folder-open', routerLinkActiveOptions: { exact: true } },
                                    { label: 'Impuestos', routerLink: ['docimpuestos'], icon: 'fa fa-fw fa-calendar', routerLinkActiveOptions: { exact: true } },
                                    
                                ]
                        },
                    ],

                ], routerLinkActiveOptions: { exact: true }, expanded: false
            },
            { label: 'Consulta', icon: 'pi pi-comments', routerLink: ['consultas'], routerLinkActiveOptions: { exact: true } },
            { label: 'Asuntos', icon: 'pi pi-list', routerLink: ['misasuntos'], routerLinkActiveOptions: { exact: true } },

            { label: 'Información', icon: 'fa fa-fw fa-book', routerLink: ['informacion'], routerLinkActiveOptions: { exact: true } },
           
            
            {
                 label: 'Laboral', icon: 'pi pi-users', title: 'Laboral',
                items: [[{
                    items: [
                        
                        { label: 'Incidencias', routerLink: ['incidencias'], icon: 'fas fa-exclamation-triangle', routerLinkActiveOptions: { exact: true } },
                        { label: 'Nóminas', routerLink: ['nominas'], icon: 'fas fa-euro-sign', routerLinkActiveOptions: { exact: true } },
                        { label: 'Resumen nóminas', routerLink: ['resnom'], icon: 'fa fa-table', routerLinkActiveOptions: { exact: true } },
                        { label: 'Doc. Empleados', routerLink: ['docsemp'], icon: 'pi pi-folder-open', routerLinkActiveOptions: { exact: true } },
                        { label: 'Pagos netos', routerLink: ['costes'], icon: 'pi pi-chart-line', routerLinkActiveOptions: { exact: true } },
                        { label: 'Portal empleados', routerLink: ['portalemp'], icon: 'pi pi-users', routerLinkActiveOptions: { exact: true }, disabled: false },
                        { label: 'Solicitudes', routerLink: ['solicitudesemp'], icon: 'pi pi-users', routerLinkActiveOptions: { exact: true }, disabled: false },
                        { label: 'Solicitud Entrada/Salida', routerLink: ['misspunchsolicitudesemp'], icon: 'pi pi-users', routerLinkActiveOptions: { exact: true }, disabled: false },
                        { label: 'Horario', routerLink: ['horarioemp'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true }, disabled: false },
                        { label: 'Consulta permisos', routerLink: ['consultapermisos'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        // { label: 'Consulta GLOBAL', routerLink: ['consultaglobal'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        { label: 'Consulta global', routerLink: ['consultaglobalmonth'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        { label: 'Portal de denuncias', routerLink: ['complaints'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        // { label: 'Responsables', routerLink: ['clientcollaborators'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        // { label: 'Departamentos', routerLink: ['clientdepartments'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        // { label: 'Proyectos', routerLink: ['clientprojects'], icon: 'pi pi-clock', routerLinkActiveOptions: { exact: true } },
                        

                    ]
                }]], routerLinkActiveOptions: { exact: true }, expanded: false
            },




            

            // { label: 'Mis datos', icon: 'pi pi-fw pi-user', routerLink: ['misdatos'], routerLinkActiveOptions: { exact: true } },

            {
                label: 'Mis datos', icon: 'pi pi-fw pi-user', title: 'Mis datos',
               items: [[{
                   items: [
                       
                       { label: 'Contraseña', routerLink: ['misdatospassword'], icon: 'pi pi-fw pi-user', routerLinkActiveOptions: { exact: true } },
                       { label: 'Datos de contacto', routerLink: ['misdatos'], icon: 'pi pi-fw pi-user', routerLinkActiveOptions: { exact: true } }
                       
            
                   ]
               }]], routerLinkActiveOptions: { exact: true }, expanded: false
            },



            // { label: 'Responsables', icon: 'pi pi-fw pi-user', routerLink: ['clientcollaborators'], routerLinkActiveOptions: { exact: true } },
            // { label: 'Departamentos', icon: 'pi pi-fw pi-user', routerLink: ['clientdepartments'], routerLinkActiveOptions: { exact: true } },

            {
                label: 'Accesos', icon: 'pi pi-table', title: 'Accesos',
               items: [[{
                   items: [
                       
                       { label: 'Departamentos', routerLink: ['clientdepartments'], icon: 'pi pi-align-center', routerLinkActiveOptions: { exact: true } },
                       { label: 'Responsables', routerLink: ['clientcollaborators'], icon: 'pi pi-circle-on', routerLinkActiveOptions: { exact: true } }
                       
            
                   ]
               }]], routerLinkActiveOptions: { exact: true }, expanded: false
            },

            
            { label: 'Proyectos', icon: 'fa fa-table', routerLink: ['clientprojects'], routerLinkActiveOptions: { exact: true } }, 
            /*  {label: 'Salir', icon: 'pi pi-sign-out',command: (event: Event) => {this.cmd_salir();},routerLinkActiveOptions: { exact: true }} */

            
        ];

        this.itemsMenu = [
            {
                label: 'Documentos', icon: 'pi pi-fw pi-video',
                items: [
                    [
                        {
                            label: 'Documentos',
                            items: [{ label: 'Enviar' }, { label: 'Mis documentos' },
                            { label: 'Impuestos' }, { label: 'Nóminas' }]
                        },
                    ],

                ]
            }];

        this.items2 = [
            { id: '0', label: 'Cambiar contraseña', icon: 'pi pi-key', command: (event: Event) => { this.menuId2 = 0; } },
            { id: '1', label: 'Cambiar datos de contacto', icon: 'pi pi-home', command: () => { this.menuId2 = 1; } }
        ];

        this.itemss = [
            { label: 'Escribir consulta' },
            { label: 'Adjuntar archivos' },
            { label: 'Enviar a gestoría' }
        ];

        this.activeItem = this.items2[0];
        this.activeItem1 = this.items1[0];

        this.items10 = [
            { id: '0', label: 'Enviar', icon: 'pi pi-upload', command: (event: Event) => { this.menuId = 0; } },
            { id: '1', label: 'Documentos', icon: 'pi pi-folder-open', command: () => { this.menuId = 1; } },
            { id: '2', label: 'Facturas', icon: 'pi pi-folder-open', command: () => { this.menuId = 2; } },
            { id: '3', label: 'Impuestos', icon: 'fa fa-fw fa-calendar', command: () => { this.menuId = 3; } }
        ];

        this.menuId = 0;

        this.items1[4].items[4].disabled = !this.gds.portalEmpleados;
        this.items1[4].items[5].disabled = !this.gds.portalEmpleados;
        this.items1[4].items[6].disabled = !this.gds.portalEmpleados || !this.gds.controlHorario;

        // this.items1X[4].items[0][0].items[4].disabled = !this.gds.portalEmpleados;
        // this.items1X[4].items[0][0].items[5].disabled = !this.gds.portalEmpleados;
        // this.items1X[4].items[0][0].items[6].disabled = !this.gds.portalEmpleados || !this.gds.controlHorario;

        var that = this;
        this.menuDataService.toggleMenuBar.subscribe(function (data: any) {

            console.log("-----cccccccccc----------",data);
            if (data && data != null) {
                that.visible = !that.visible;
                that.isMenuVisible = !that.isMenuVisible;
            }
        });

        if (this.applicationStateService.getIsMobileResolution()) {
            this.visible = false;
            this.isMenuVisible = false;
        } else {
            this.visible = true;
            this.isMenuVisible = true;
        }

        var activeMenu = this.sessionService.getItem("active-menu");
        if (activeMenu) {
            this.selectedItem = activeMenu;
        } else {
            this.selectedItem = "Recientes";
        }
    }


    ngOnDestroy() {
        this.menuDataService.toggleMenuBar.observers.forEach(function (element) { element.complete(); });
    }

    // on menu click event
    onMenuClick(menu: MegaMenuItem) {
        //alert(menu.routerLink);
        // if child are available then open child
        if (menu.items != undefined || menu.items != null) {
            this.toggleSubMenu(menu);
            return;
        }
        //alert(menu.label);
        
        

        /*  if (menu.routerLink == undefined || menu.routerLink == null || menu.routerLink == "") {
             return;
        } */
        this.selectedItem = menu.label;
        this.sessionService.setItem("active-menu", menu.label);
        //this.routeStateService.add(menu.label, menu.RouterLink, null, true);
        // hide menu bar after menu click for mobile layout    

        this.routeStateService.add(menu.label, menu.routerLink[0], null, true);
        //this.router.navigate(menu.routerLink, { relativeTo: this.routera });
        this.router.navigate(menu.routerLink, { relativeTo: this.routera });
        // hide menu bar after menu click for mobile layout        
        setTimeout(() => {
            if (this.applicationStateService.getIsMobileResolution()) {
                this.visible = false;
            }
        }, 100);
    }

    // toggle sub menu on click
    toggleSubMenu(menu: MegaMenuItem) {
        menu.expanded = !menu.expanded;
    }

    cmd_salir() {
        this.sessionService.removeItem('active-menu');
        this.gds.logoutCliente();
        // debugger;
        this.router.navigate(['/login']);
    }

    salir() {
        document.getElementById("menu2").classList.remove("menu2");
        document.getElementById("menu2").classList.add("menu3");
        this.items1 = this.items10;
    }

    openMenu(e) {
        this.menuCtx.toggle(e);
        e.stopPropagation();
    }

    toggleMenu() {
        this.menuDataService.toggleMenuBar.next(true);
    }

    selectTheme(theme: string) {
        this.sessionService.setItem("selected-theme", theme);
        this.themeService.selectTheme(theme);
    }
    selectChange() {
        this.companydata = this.subcompanies.filter(id => id.idCliente == this.mySelect);
    }
    updateUrl_old() {


        let { cif, apikey } = this.companydata[0];
        let url = window.location.href.split("#")[0];


        // local access
        //let navigation = `${url}clientes/#/login/user/${cif}/apikey/${apikey}`
        // remote access
        let navigation = `${url}#/login/user/${cif}/apikey/${apikey}`;
        
        //alert(navigation);
        window.location.replace(navigation);




    }
    updateUrl() {
        console.log(this.companydata[0]);

        if(this.idClient_collaborator!=0)
        {
            let { idCliente } = this.companydata[0];
            //alert(idCliente);
            let cif=this.collaborator_cif;
            let apikey=this.collaborator_apikey;
            let url = window.location.href.split("#")[0];
            
    
            // local access
            //let navigation = `${url}clientes/#/login/user/${cif}/apikey/${apikey}`
            // remote access
            let navigation = `${url}#/login/user/${cif}/apikey/${apikey}/idCliente/${idCliente}`;
            //alert(navigation);
        window.location.replace(navigation);

        }
        else{
            let { cif, apikey } = this.companydata[0];
            let url = window.location.href.split("#")[0];
    
    
            // local access
            //let navigation = `${url}clientes/#/login/user/${cif}/apikey/${apikey}`
            // remote access
            let navigation = `${url}#/login/user/${cif}/apikey/${apikey}`;
            //alert(navigation);
        window.location.replace(navigation);
        }
        

        
        




    }
}
