<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Documentación empleados</p>   
    
<div class="grid filtro r-grid r-grid12-12-6">
    <div class="g-w6field">
        <small>Ejercicio:</small>
        <p-dropdown  [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="filtrarEj();"></p-dropdown>
    </div>
    <div class="g-w6field">
        <small>Empleado:</small>
        <p-dropdown (onChange)="filtrar();" [autoDisplayFirst]="false" [showClear]="false" [options]="empleados" [(ngModel)]="empleado"></p-dropdown>
    </div>
  

<!--
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
        <label for="firstname1">Firstname</label>
        <p-dropdown id="firstname1" [autoDisplayFirst]="false" [showClear]="true" [options]="empleados" [(ngModel)]="empleado" (onChange)="filtrar();"></p-dropdown>
    </div>
    <div class="p-field p-col">
        <label for="lastname1">Lastname</label>
        <p-dropdown id="lastname1" [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="filtrar();"></p-dropdown> 
    </div>
</div>   
-->
<div class="g-w12field">  

    <p-tree  emptyMessage="No existen documentos para este empleado!" selectionMode="single" (onNodeSelect)="gds.viewTreeDoc($event)" [value]="itemsArbol" styleClass="arbol-docs"  [style]="{width: '100%'}">
            
        <ng-template let-node pTemplate="rama">
            {{node.label}}
        </ng-template>
        
        <ng-template let-node pTemplate="doc" >
            <p>[{{node.data['fechaEjercicio']}}] {{node.label}}</p> 
        </ng-template>
        
    </p-tree>
</div>
</div>  
