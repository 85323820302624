<app-header-breadcrumb></app-header-breadcrumb>
<p class="tituloSeccion">Incidencios empleados</p>   
    
<div class="grid filtro r-grid r-grid12-12-6">
    <div class="g-w6field">
        <small>Ejercicio:</small>
        <p-dropdown  [autoDisplayFirst]="false" [showClear]="true" [options]="gds.ejercicios" [(ngModel)]="ejercicio" (onChange)="filtrarEj();"></p-dropdown>
    </div>
    <div class="g-w6field">
        <small>Empleado:</small>
        <p-dropdown (onChange)="filtrar();" [autoDisplayFirst]="false" [showClear]="false" [options]="empleados" [(ngModel)]="empleado"></p-dropdown>
    </div>
    <div class="g-w12field">
    <div class="example-column">        
    <p-table [value]="incidencias" selectionMode="single"  [(selection)]="selectedIncidencia" 
    [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" >
        <ng-template pTemplate="header">
            <tr>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Incidencia</th>
                <th>Observaciones</th>
               
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-incidencia>
            <tr [pSelectableRow]="incidencia">
                <td>{{incidencia.estado}}</td>
                <td>{{incidencia.periodo}} </td>
                <td>{{incidencia.solicitud}}</td>
                <td>{{incidencia.obs}}</td>
            </tr>
        </ng-template>

    </p-table>    
</div>
</div>

</div>  




<!-- <div class="example-column">
    <p-table [value]="incidencias" >
        <ng-template pTemplate="header">
            <tr>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Incidencia</th>
                <th>Observaciones</th>
                <th width="10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-incidencia>
            <tr [pSelectableRow]="incidencia">
                <td>{{incidencia.estado}}</td>
                <td>{{incidencia.periodo}} </td>
                <td>{{incidencia.solicitud}}</td>
                <td>{{incidencia.obs}}</td>
                <td>
                    
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>
     -->

<!-- <p-table [value]="incidencias" (click)="click_tabla($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>Empleado</th>
            <th>Importe</th>
            <th>Documentos</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-incidencia>
        <tr>
            <td>{{incidencia.empleado}}</td>
            <td>{{incidencia.importe| number:'1.2':'es'}}€</td>
            <td><i [attr.data-id]="doc[0]" class="fa fa-file icono enlace" *ngFor="let doc of incidencia.incidencias"></i></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        Importe total: {{total| number:'1.2'}}€
    </ng-template>
</p-table> -->

